<template>
  <div class="container">
    <div class="col-md-8 m-auto">
      <div class="thank-you-page">
        <img src="../../assets/img/email.png" alt="">
        <h1>Thank you {{name}}</h1>
        <p>
          Thank you for your great generosity! We, at PeaceJam, greatly appreciate your donation and your sacrifice. Your support is invaluable to us, thank you again!
        </p>
        <router-link to="/" class="default-btn">Go Home</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Success",
  data () {
    return {
      name: ''
    }
  },
  created() {
    window.scrollTo(0, 0);
    if (this.$route.params.name) {
      this.name = window.atob(this.$route.params.name)
    }
  }
}
</script>

<style scoped lang="scss">
 .thank-you-page {
   padding-top: 180px;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding-bottom: 100px;

   h1 {
     font-weight: 400;
     font-size: 50px;
   }
   p {
     text-align: center;
   }
 }
</style>
